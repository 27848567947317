<template>
    <v-container fluid style="padding-bottom: 100px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-title class="lighten-5 p-2">
                        <v-row>
                            <v-col cols="12" class="mb-3 pb-0">
                                <div class="d-flex p-2 mb-0 pb-0">
                                    <div class="d-flex" style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                                        <div style="min-width: 70px;">
                                            Header
                                        </div>
                                        <div>
                                            <p class="mt-1 ml-3 p-0 mb-0" style="font-size: 10px;max-width: 350px;">
                                                * Field are required. Please fill your fields and submit for save
                                            </p>
                                        </div>
                                    </div>                                   
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    color="primary"
                                    fab
                                    x-small
                                    dark
                                    class="ma-0"
                                    @click="dialog = true"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mt-3">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-6" sm="6" md="2">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">Trans Date <strong style="color:red;">*</strong><span></span></h6>
                                    <v-menu
                                        ref="modal_request"
                                        v-model="modal_request"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date"
                                            label="Tanggal Maintenance"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            :disabled="disabled_form"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            no-title
                                            @input="[modal_request = false]"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Shift <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="shift"
                                        :items="shifts"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="disabled_form"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Mill <span class="red--text"><strong>* </strong></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mill"
                                        :items="mills"
                                        item-value="mill_id"
                                        :item-text="item => item.mill_id +' - '+ item.mill_name.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        @change="(event) => getMachType(event)"
                                        :disabled="disabled_form"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine Type <span class="red--text"><strong>* </strong></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_type"
                                        :items="mach_types"
                                        item-value="mach_type"
                                        :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        @change="(event) => [getMachID(event), getMaintenanceSection(event)]"
                                        :disabled="disabled_form"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine ID <span class="red--text"><strong>* </strong></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_id"
                                        :items="mach_ids"
                                        item-value="mach_id"
                                        :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="disabled_form"
                                        :loading="loading_mach_id"
                                        @change="(event) => getMaintenanceSection(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Maint. Section <span class="red--text"><strong>* </strong></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="main_section"
                                        :items="main_sections"
                                        item-value="section_id"
                                        :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="disable_sectid"
                                        :loading="isLoadingMainSection"
                                        @change="(event) => getPartMachine(event, 'onChange')"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Part List <span class="red--text"><strong>* </strong></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="part_mach"
                                        :items="part_machs"
                                        item-value="part_id"
                                        :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        return-object
                                        @change="(event) => getPeriode(event)"
                                        :disabled="disabled_form"
                                        :loading="isLoadingPartList"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Periode <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="periode"
                                        :items="periodes"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="true"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="4" class="col-12 hidden-mobile">
                                    <v-btn class="mt-7 border-12 mr-2" color="secondary" @click="[clear()]"><v-icon class="mr-1">mdi-backspace</v-icon>Clear</v-btn>
                                    <v-btn class="mt-7 border-12 mr-2" style="padding: 19px;" small color="primary" elevation="2" @click="getMasterMaintenance('SR')" ><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                                </v-col>
                                <v-col xs="6" sm="6" md="4" class="col-12 hidden-dekstop">
                                    <v-btn class="mt-7 border-12 mr-2" color="secondary" @click="[clear()]"><v-icon class="mr-1">mdi-backspace</v-icon>Clear</v-btn>
                                    <v-btn class="mt-7 border-12 mr-2" style="padding: 19px;" small color="primary" elevation="2" @click="getMasterMaintenance('SR')" ><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                                    <v-btn class="mt-7 border-12" style="padding: 19px;" small elevation="2" @click="[dialog_scan = true, result_scan = '']"><v-icon class="mr-1">mdi-barcode-scan</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-dialog
                        v-model="dialog"
                        max-width="600px"
                        persistent
                    >
                        <v-card style="overflow: hidden;">
                            <v-card-title class="p-1">                        
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="dialog = false"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text class="mb-0">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <h6 class="text-center" style="font-weight:600">
                                                {{ tr_id ? tr_id : '' }}
                                            </h6>
                                        </v-col>
                                        <v-col cols="12">
                                            <h6 class="blue-lcd" style="font-weight:600">Remark </h6>
                                            <v-textarea
                                                solo
                                                name="input-7-4"
                                                v-model="remark"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="text-center p-3 mt-0">
                                <v-row justify="center">
                                    <v-col cols="6" xs="6" sm="3" md="3">
                                        <v-btn block class="rounded-l p-3" @click="[dialog = false, remark = '']">Cancel</v-btn>
                                    </v-col>
                                    <v-col cols="6" xs="6" sm="3" md="3">
                                        <v-btn block class="rounded-l text-white p-3" color="primary" @click="saveHeader()">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="dialog_scan"
                        max-width="800px"
                        persistent
                    >
                        <v-card style="overflow: hidden;">
                            <v-card-title class="p-1">                        
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="dialog_scan = false"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text class="mb-0">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <StreamBarcodeReader
                                                @decode="onDecode"
                                                @loaded="onLoaded"
                                            ></StreamBarcodeReader>
                                        </v-col>
                                        <v-col cols="12">
                                            Result Scan : {{ result_scan }}
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="text-center p-3 mt-0">
                                <v-row justify="center">
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <v-btn block class="rounded-l p-3" @click="dialog_scan = false">Submit QR Code</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="alert">
                <v-alert
                dismissible
                type="warning"
                style="padding: 8px !important;"
                max-width="400"
                >
                Value out of range
                </v-alert>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0 hidden-dekstop" height="500">
                    <v-card-title class="mt-0 mb-0 pb-0">
                        Result
                        <v-spacer></v-spacer>
                        <v-menu
                        open-on-hover
                        bottom
                        offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                x-small
                                color="#fff"
                                class="py-5"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon>mdi-filter-variant</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item
                                v-for="(item, index) in number_of_seqs"
                                :key="index"
                                @click="changeNumberSeq(item)"
                                >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>
                    <v-card-text class="mt-0 pl-2 pr-2">
                        <v-container fluid>
                            <v-row v-if="mst_maintenances.length > 0">
                                <v-col class="mt-2" cols="12" v-for="(item, key) in mst_maintenances" :key="key">
                                    <v-card outlined class="p-0 blue lighten-5">
                                        <v-card-title class="mt-0 mb-0 p-2 pb-0" style="font-size: 12px !important;font-weight: 600 !important;line-height: 1rem !important;">
                                            {{item.check_descr}}
                                        </v-card-title>
                                        <v-card-subtitle class="mt-0 mb-0 p-2 pt-0 pb-0" style="font-size: 10px !important;">
                                            SEQ : {{item.seq_no}} | min : {{ item.data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.min) : '-' }}, max : {{ item.data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.max) : '-' }}
                                        </v-card-subtitle>
                                        <v-card-text class="mt-0 p-2">
                                            <v-container fluid class="mb-2">
                                                <v-row class="justify-end">

                                                    <v-col class="mt-0 justify-end" cols="12">
                                                        <h6 class="blue-lcd mb-1 pa-1">Result </h6>
                                                        <div v-if="periode === 'DAY'">
                                                            <div v-if="item.data_type === 'STRING' || item.data_type === 'NUMERIC'">
                                                                <v-text-field
                                                                    solo
                                                                    dense
                                                                    class="border-12 ma-0 pa-0"
                                                                    v-model="item.check_result_temp"
                                                                    single-line
                                                                    hide-details
                                                                    :disabled="item.check_result && periode === 'DAY' && item.shift_id != shift ? true : false"
                                                                    
                                                                    :type="item.data_type === 'NUMERIC' ? 'number' : 'text'"    
                                                                    
                                                                ></v-text-field>
                                                            </div>
                                                            <div v-else-if="item.data_type === 'CHECK'">
                                                                <v-radio-group
                                                                    v-model="item.check_result_temp"
                                                                    :disabled="item.check_result && periode === 'DAY' && item.shift_id != shift ? true : false" 
                                                                    row
                                                                    hide-details
                                                                    
                                                                    class="ma-1 pa-1">
                                                                    <v-radio
                                                                        label="Yes"
                                                                        value="Y"
                                                                        color="primary"
                                                                        class="ma-0 pa-0 mr-2"
                                                                        hide-details
                                                                    ></v-radio>
                                                                    <v-radio
                                                                        label="No"
                                                                        value="N"
                                                                        color="error"
                                                                        class="ma-0 pa-0"
                                                                        hide-details
                                                                    ></v-radio>
                                                                </v-radio-group>
                                                            </div>
                                                        </div>

                                                        <div v-if="periode === 'SHIFT' || periode === 'TIME'">
                                                            <div v-if="item.data_type === 'STRING' || item.data_type === 'NUMERIC'">
                                                                <v-text-field
                                                                    solo
                                                                    dense
                                                                    class="border-12 ma-0 pa-0"
                                                                    v-model="item.check_result_temp"
                                                                    single-line
                                                                    hide-details    
                                                                    
                                                                    :type="item.data_type === 'NUMERIC' ? 'number' : 'text'"
                                                                    :disabled="item.check_result == null || (parseInt(item.shift_id) === parseInt(shift) && (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) === (new Date(new Date(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)) ? false : true"       
                                                                    
                                                                ></v-text-field>
                                                                <!-- :append-outer-icon="'mdi-send'" 
                                                                @click:append-outer="submit(item)"                                             -->
                                                            </div>
                                                            <div v-else-if="item.data_type === 'CHECK'">
                                                                <v-radio-group
                                                                    v-model="item.check_result_temp"
                                                                    :disabled="item.check_result == null || (parseInt(item.shift_id) === parseInt(shift) && (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) === (new Date(new Date(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)) ? false : true"  
                                                                    row
                                                                    hide-details
                                                                    >
                                                                    <!-- @change="submit(item)" class="ma-1 pa-1" -->
                                                                    <v-radio
                                                                        label="Yes"
                                                                        value="Y"
                                                                        color="primary"
                                                                        class="ma-0 pa-0 mr-2"
                                                                        hide-details
                                                                    ></v-radio>
                                                                    <v-radio
                                                                        label="No"
                                                                        value="N"
                                                                        color="error"
                                                                        class="ma-0 pa-0"
                                                                        hide-details
                                                                    ></v-radio>
                                                                </v-radio-group>
                                                            </div>
                                                        </div>

                                                    </v-col>

                                                    <v-col class="mt-0 justify-end" cols="12">
                                                        <v-text-field
                                                            solo
                                                            dense
                                                            class="border-12 ma-0 pa-0"
                                                            label="Remark"
                                                            v-model="item.remark_detail"
                                                            single-line
                                                            hide-details    
                                                        ></v-text-field>
                                                        <!-- :append-outer-icon="'mdi-send'" 
                                                            @click:append-outer="submit(item)" -->
                                                    </v-col>
                                                    <v-col class="mt-0 justify-end" cols="12">
                                                        <v-btn block class="border-12" style="padding: 19px;" small color="primary" elevation="2" @click="submit(item)">Submit</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else align="center" justify="center" style="margin-top: 150px; margin-bottom: 50px">
                                <v-col class="mt-2" cols="12">
                                    <v-img class="text-center" src="@/assets/logo_header.png" alt="Admin" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>

                <v-card outlined class="p-0 hidden-mobile">
                    <v-card-text class="mt-0">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="headers"
                                        :items="mst_maintenances"
                                        class="elevation-1"
                                        :items-per-page="-1"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="$store.state.overlay"
                                        height="400"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                        :item-class="tr_datatable"
                                        :search="seq_no"
                                        :custom-filter="filterSeqNo"
                                    >
                                        <template v-slot:top>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="seq_no"
                                                :items="number_of_seqs"
                                                item-value="value"
                                                :item-text="item => item.text"
                                                class="border-12 ml-3 mr-3 mb-2"
                                                style="max-width: 200px;"
                                                hide-details=true
                                            ></v-autocomplete>
                                        </template>
                                        <template v-slot:[`item.min`]="{ item }">
                                            {{ item.data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.min) : '-' }}
                                        </template>
                                        <template v-slot:[`item.max`]="{ item }">
                                            {{ item.data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.max) : '-' }}
                                        </template>
                                        <template v-slot:[`item.trans_date`]="{ item }">
                                            {{ (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                        </template> 
                                        <template v-slot:[`item.check_result`]="{ item }">
                                            <div v-if="periode === 'DAY'">
                                                <div v-if="item.data_type === 'STRING' || item.data_type === 'NUMERIC'">
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        class="border-12 ma-0 pa-0"
                                                        v-model="item.check_result_temp"
                                                        single-line
                                                        hide-details
                                                        :disabled="item.check_result && periode === 'DAY' && item.shift_id != shift ? true : false"
                                                        :type="item.data_type === 'NUMERIC' ? 'number' : 'text'"    
                                                    ></v-text-field>
                                                    <!-- :append-outer-icon="'mdi-send'"  -->
                                                    <!-- @click:append-outer="submit(item)" -->
                                                </div>
                                                <div v-else-if="item.data_type === 'CHECK'">
                                                    <!-- @change="submit(item)" -->
                                                    <v-radio-group
                                                        v-model="item.check_result_temp"
                                                        :disabled="item.check_result && periode === 'DAY' && item.shift_id != shift ? true : false" 
                                                        row
                                                        hide-details
                                                        class="ma-1 pa-1">
                                                        <v-radio
                                                            label="Y"
                                                            value="Y"
                                                            color="primary"
                                                            class="ma-0 pa-0 mr-2"
                                                            hide-details
                                                        ></v-radio>
                                                        <v-radio
                                                            label="N"
                                                            value="N"
                                                            color="error"
                                                            class="ma-0 pa-0"
                                                            hide-details
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </div>
                                            </div>
                                            <div v-if="periode === 'SHIFT' || periode === 'TIME'">
                                                <div v-if="item.data_type === 'STRING' || item.data_type === 'NUMERIC'">
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        class="border-12 ma-0 pa-0"
                                                        v-model="item.check_result_temp"
                                                        single-line
                                                        hide-details    
                                                        :type="item.data_type === 'NUMERIC' ? 'number' : 'text'"
                                                        :disabled="item.check_result == null || (parseInt(item.shift_id) === parseInt(shift) && (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) === (new Date(new Date(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)) ? false : true"       
                                                    ></v-text-field>
                                                    <!-- :append-outer-icon="'mdi-send'" 
                                                    @click:append-outer="submit(item)" -->
                                                </div>
                                                <div v-else-if="item.data_type === 'CHECK'">
                                                    <!-- @change="submit(item)" class="ma-1 pa-1" -->
                                                    <v-radio-group
                                                        v-model="item.check_result_temp"
                                                        :disabled="item.check_result == null || (parseInt(item.shift_id) === parseInt(shift) && (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) === (new Date(new Date(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)) ? false : true"  
                                                        row
                                                        hide-details>
                                                        <v-radio
                                                            label="Y"
                                                            value="Y"
                                                            color="primary"
                                                            class="ma-0 pa-0 mr-2"
                                                            hide-details
                                                        ></v-radio>
                                                        <v-radio
                                                            label="N"
                                                            value="N"
                                                            color="error"
                                                            class="ma-0 pa-0"
                                                            hide-details
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </div>
                                            </div>
                                        </template> 
                                        <template v-slot:[`item.remark_detail`]="{ item }">
                                            <v-text-field
                                                solo
                                                dense
                                                class="border-12 ma-0 pa-0"
                                                v-model="item.remark_detail"
                                                single-line
                                                hide-details    
                                            ></v-text-field>
                                            <!-- :append-outer-icon="'mdi-send'"  -->
                                            <!-- @click:append-outer="submit(item)" -->
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-2" @click="submit(item)" v-on="on">
                                                            mdi-send
                                                        </v-icon>
                                                    </template>
                                                    <span>Submit</span>
                                                </v-tooltip>
                                            </td>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"
import { Money } from 'v-money'
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    components: {
        Money, StreamBarcodeReader
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                },
                {
                    text: 'Create',
                    disabled: true,
                }
            ],
            mills:[],
            mill: 'SR        ',
            mach_type: '',
            mach_types: [],
            mach_id: '',
            mach_ids: [],
            main_section: '',
            main_sections: [],
            part_mach: '',
            part_machs: [],
            mst_maintenance: '',
            mst_maintenances: [],
            periodes: [
                {
                    text: 'DAY',
                    value: 'DAY'
                },
                {
                    text: 'SHIFT',
                    value: 'SHIFT'
                },
                {
                    text: 'TIME',
                    value: 'TIME'
                }
            ],
            periode: '',
            modal_request: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            shifts: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                }
            ],
            shift: '',
            disabled_shift: false,
            disable_sectid: false,
            details: [],
            disabled_form: false,
            headerSet: false,
            headers:[
                // { text: 'Trans Date', value: 'trans_date',width:'200' , align:'left', groupable: false},
                // { text: 'Check List', value: 'descr' , align:'left', groupable: false},
                { text: 'Check Descr', value: 'check_descr' , align:'left', class: 'my-header-style'},
                { text: 'Shift', value: 'shift_id',width:'100' , align:'left', groupable: false},
                { text: 'Seq No', value: 'seq_no',width:'100' , align:'left', groupable: false},
                { text: 'Min', value: 'min',width:'100' , align:'right', groupable: false},
                { text: 'Max', value: 'max',width:'100' , align:'right', groupable: false},
                { text: 'Result', value: 'check_result',width:'250' , align:'left', groupable: false},
                { text: 'Remark', value: 'remark_detail', width:'250' , align:'left', groupable: false},
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            tr_id: '',
            remark: '',
            number_of_seqs: [],
            seq_no: '',
            remark_detail:'',
            dialog: false,
            envExist: false,
            envExistArray: false,
            isLoadingMainSection: false,
            alert: false,
            isMobile: false,
            dialog_scan: false,
            result_scan: '',
            result_json: null,
            loading_mach_id: false,
            isLoadingPartList: false
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.isMobileFunc()
        await this.getMill()
        await this.getMachType('SR')
        // await this.getMachID('SR')
        // await this.getMaintenanceSection('SR')
        // await this.getPartMachine('SR')
        // await this.getEnvConf()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        filterSeqNo (value, search, item) {
            // console.log(value, item, search);
            return value != null &&
            search != null && item.seq_number == search
        },
        isMobileFunc() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        clear(){
            this.headerSet = false
            this.mach_id = ''
            // this.main_section = ''
            this.part_mach = ''
            this.periode = ''
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.mst_maintenances = []
            this.disabled_form = false
            if(!this.envExist){
                this.disable_sectid = false
            }
            this.remark = ''
            this.tr_id = ''
        },
        async onDecode(text) {
            this.result_scan = text

            if (this.result_scan) {

                this.result_json = JSON.parse(this.result_scan);
                this.mach_type = this.result_json ? this.result_json.mach_type : ''

                if (this.mach_type) {
                    this.dialog_scan = false
                    await this.getMachID(this.mach_type)
                    this.mach_id = this.result_json ? this.result_json.mach_id : ''

                    if (this.envExist) {
                        var main_section = this.result_json ? this.result_json.section_id : ''
                        if (this.envExistArray) {
                            const arrayIndex = this.main_sections.findIndex(x => x.section_id === main_section);
                            if (arrayIndex) {
                                await this.getMaintenanceSection(this.mach_id)
                                this.main_section = main_section
                                await this.getPartMachine(this.result_json ? this.result_json.part_id : '', 'scan')
                                this.part_mach = this.part_machs.length > 0 ? this.part_machs[0] : ''
                                await this.getPeriode(this.part_mach)
                            } else {
                                this.snackbar = {
                                    color: "warning",
                                    icon: "mdi-alert-circle",
                                    mode: "multi-line",
                                    position: "top",
                                    timeout: 7500,
                                    title: "Warning",
                                    text: "You Can't access this section",
                                    visible: true
                                };

                                return false;
                            }
                        } else {
                            if (main_section == this.main_section) {
                                await this.getPartMachine(this.result_json ? this.result_json.part_id : '', 'scan')
                                this.part_mach = this.part_machs.length > 0 ? this.part_machs[0] : ''
                                await this.getPeriode(this.part_mach)
                            } else {
                                this.snackbar = {
                                    color: "warning",
                                    icon: "mdi-alert-circle",
                                    mode: "multi-line",
                                    position: "top",
                                    timeout: 7500,
                                    title: "Warning",
                                    text: "You Can't access this section",
                                    visible: true
                                };

                                return false;
                            }
                        }
                    } else {
                        await this.getMaintenanceSection(this.mach_id)
                        this.main_section = this.result_json ? this.result_json.section_id : ''
                        await this.getPartMachine(this.result_json ? this.result_json.part_id : '', 'scan')
                        this.part_mach = this.part_machs.length > 0 ? this.part_machs[0] : ''
                        await this.getPeriode(this.part_mach)
                    }                    
                } else {
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: 'Data not found',
                        visible: true
                    };

                    this.dialog_scan = true

                }
                
            }
            console.log(`Decode text from QR code is ${text}`)
        },
        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        },
        async getEnvConf(){
            const respVarSectID = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SR&appl_id=WEBSR&var_id=MAINTSECT&groupid=${this.$store.state.sr.group_id}`, null, false, false, false) 

            if (respVarSectID.data.data != null) {
                var arrayrespVarSectID = respVarSectID.data.data.split(",");
                this.envExist = true

                if (arrayrespVarSectID.length > 1) {
                    var arrayReusable = []
                    for (let index = 0; index < arrayrespVarSectID.length; index++) {
                        const element = arrayrespVarSectID[index];
                        const arrayIndex = this.main_sections.findIndex(x => x.section_id === element);
                        arrayReusable.push(this.main_sections[arrayIndex]);
                    }

                    this.main_sections = arrayReusable
                    this.envExistArray = true
                } else {
                    this.main_section = respVarSectID.data.data
                    this.disable_sectid = true
                    this.envExistArray = false
                    await this.getPartMachine(this.main_section, 'onChange')
                }
                
            } else {
                this.main_section = ''
                this.disable_sectid = false
            }
        },
        async getMill(){
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mill?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.mills = respData.data.data
            }
        },
        async getMachType(event){
            if (event === '' || event === null) {
                this.mach_type = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-type?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_types = respData.data.data
                }
            }
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                this.loading_mach_id = true
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-id?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_ids = respData.data.data
                    this.loading_mach_id = false
                }
            }
        },
        async getMaintenanceSection(event){
            if (event === '' || event === null) {
                this.main_section = ''
            } else {
                this.isLoadingMainSection = true
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/maint-section?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&active_flag=Y&mach_id=${this.mach_id ? this.mach_id : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.main_sections = respData.data.data
                    await this.getEnvConf()
                    this.isLoadingMainSection = false
                }
            }
        },
        async getPartMachine(event, change){
            if (event === '' || event === null) {
                this.part_mach = ''
            } else {
                this.isLoadingPartList = true
                this.part_machs = []
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/part-of-mach?mill_id=${this.mill ? this.mill.trim() : ''}&section_id=${this.main_section ? this.main_section : ''}&active_flag=Y&part_id=${change === 'onChange' ? '' : event}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.part_machs = respData.data.data
                    this.isLoadingPartList = false
                }
            }
        },
        getPeriode(event){
            console.log('getPeriode')
            console.log(event)
            console.log(this.periode)
            if (event === '' || event === null) {
                this.periode = ''
            } else {
                this.periode = event.period.trim() ? event.period : ''
            }
            console.log(this.periode)
        },
        async getMasterMaintenance(event){
            this.$store.dispatch('setOverlay', true)

            if (this.mill == '' || this.main_section === '' || this.part_mach === '' || this.shift === '' || this.periode === '' ||  this.mach_id === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields and submit for save',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }
            // this.mst_maintenances = []
            this.number_of_seqs = []
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mst-maintenance?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}&section_id=${this.main_section ? this.main_section.trim() : ''}&part_id=${this.part_mach ? this.part_mach.part_id.trim() : ''}&period=${this.periode ? this.periode : ''}&shift_id=${this.shift ? this.shift : ''}&trans_date=${this.date ? (new Date(new Date(this.date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                console.log(respData.data);
                if (respData.data.data.data_header.length > 0) {
                    const header = respData.data.data.data_header[0]
                    this.tr_id = header.tr_id
                    this.remark = header.remark ? header.remark : ''
                    for (let index = 0; index < Number(header.num_of_period); index++) {
                        var number = index + 1
                        this.number_of_seqs.push(
                            {
                                text : index + 1,
                                value : number.toString()
                            }
                        )                        
                    }
                }
                this.mst_maintenances = respData.data.data.data_detail

                this.mst_maintenances = this.mst_maintenances.map(maintenance => {
                    maintenance.check_result_temp = maintenance.check_result;
                    maintenance.seq_number = maintenance.seq_no.toString();
                    return maintenance;
                });        
            }
            this.headerSet = true
            this.disabled_form = true
            this.disable_sectid = true
            this.$store.dispatch('setOverlay', false)

        },
        submitDetail(){
            this.details.push(
                {
                    item_num : this.details.length + 1,
                    mst_maintenance: null,
                    check: '',
                }
            )
        },
        deleteDetail(detail, index){
            this.details.splice(index, 1);
        },
        async submit(detail){
            this.$store.dispatch('setOverlay', true)

            if (detail.data_type === 'NUMERIC' && (detail.check_result_temp <= Number(detail.min) || detail.check_result_temp >= Number(detail.max))) {
                this.$store.dispatch('setOverlay', false)
                this.alert = true
            } else {
                this.alert = false
            }

            var reqBody = {
                'mill_id': detail.mill_id ? detail.mill_id.trim() : 'SR',
                'mach_type': this.mach_type ? this.mach_type : '',
                'mach_id': this.mach_id ? this.mach_id : '',
                'section_id': detail.section_id ? detail.section_id : this.main_section,
                'part_id': detail.part_id ? detail.part_id.trim() : this.part_mach.part_id,
                'period': detail.period ? detail.period : this.periode,
                // 'shift_id': detail.shift_id ? detail.shift_id : this.shift,
                'shift_id': this.shift,
                'check_id': detail.check_id,
                'check_result': detail.check_result_temp,
                'maintained_by': detail.maintained_by,
                'tr_id': detail.tr_id ? detail.tr_id.trim() : '',
                'seq_no': detail.seq_no ? detail.seq_no : '',
                'trans_date': detail.trans_date ? (new Date(new Date(detail.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : (new Date(new Date(this.date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                'remark': this.remark ? this.remark : '',
                'remark_detail': detail.remark_detail ? detail.remark_detail : '',
            }
            const respData = await backendApi.fetchCore('/v2/sr/maintenance/update-detail', reqBody, false, false, false)
            if (respData.status === 200) {
                if (this.remark) {
                    const respDataHeader = await backendApi.fetchCore('/v2/sr/maintenance/update-header', reqBody, false, false, false)
                }
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: respData.data.message,
                    visible: true
                };
                // await this.getMasterMaintenance('SR') 
                this.$store.dispatch('setOverlay', false)
            } else{
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        async saveHeader(){
            this.dialog = false
            this.$store.dispatch('setOverlay', true)

            if (this.tr_id === '' || this.tr_id === null) {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill header',
                    visible: true
                };
                this.dialog = false

                return false
            }
            var reqBody = {
                'mill_id': this.mill ? this.mill : 'SR',
                'mach_type': this.mach_type ? this.mach_type : '',
                'mach_id': this.mach_id ? this.mach_id : '',
                'section_id': this.main_section ? this.main_section : '',
                'part_id': this.part_mach ? this.part_mach.part_id : this.part_mach.part_id,
                'period': this.periode ? this.periode : '',
                'shift_id': this.shift ? this.shift : '',
                'tr_id': this.tr_id ? this.tr_id : '',
                'trans_date': this.date ? (new Date(new Date(this.date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : '',
                'remark': this.remark ? this.remark : '',
            }
            const respData = await backendApi.fetchCore('/v2/sr/maintenance/update-header', reqBody, false, false, false)
            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: respData.data.message,
                    visible: true
                };
                this.dialog = false
                await this.getMasterMaintenance('SR')
                this.$store.dispatch('setOverlay', false)
            } else{
                this.dialog = false
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        changeNumberSeq(item){
            console.log(item);
            console.log(this.mst_maintenances);
        }
    },
    watch: {
        isMobile: {
            handler () {
                this.isMobileFunc()
            },
            deep: true
        },
    }    
}
</script>
<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.v-card {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>